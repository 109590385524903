"use client";

import { type ReactNode, createContext, useContext } from "react";

import { type getSubjects } from "~/server/cms/subjects";

interface Value {
  subjects: Awaited<ReturnType<typeof getSubjects>>;
}

const SubjectsContext = createContext<Value>({
  subjects: {},
});

interface Props {
  value: Value;
  children: ReactNode;
}

export const SubjectsProvider: React.FC<Props> = ({ value, children }) => {
  return (
    <SubjectsContext.Provider value={value}>
      {children}
    </SubjectsContext.Provider>
  );
};

export const useSubjects = () => {
  const context = useContext(SubjectsContext);

  if (context === undefined) {
    throw new Error("useSubjects must be used within a SubjectsProvider");
  }

  function getSubjectName(subject: string) {
    if (subject === "UNKNOWN") {
      return "\u00A0";
    }
    return context.subjects[subject]?.label ?? subject;
  }

  return {
    subjects: context.subjects,
    getSubjectName,
    subjectsOptions: Object.values(context.subjects).map((subject) => ({
      value: subject.id,
      label: subject.label,
    })),
  };
};
